var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userData ? _c('div', {}, [_c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-sm-flex d-none user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0"
        }, [_vm._v(" " + _vm._s(_vm.userData.name) + " ")]), _c('span', {
          staticClass: "user-status"
        }, [_vm._v(_vm._s(_vm.userData.level.nama_level))])]), _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "src": _vm.userData.avatar,
            "variant": "light-primary",
            "badge": "",
            "badge-variant": "success"
          }
        }, [!_vm.userData.name ? _c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "22"
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 711097914)
  }, [_vm.userData.level.nama_level == 'Siswa' ? _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'statistik'
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "UserIcon"
    }
  }), _c('span', [_vm._v("Dashboard")])], 1) : _vm.userData.level.nama_level == 'Super Admin' ? _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'admin-dashboard'
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "HomeIcon"
    }
  }), _c('span', [_vm._v("Dashboard")])], 1) : _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'user-admin-dashboard'
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "HomeIcon"
    }
  }), _c('span', [_vm._v("Dashboard")])], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v("Logout")])], 1)], 1)], 1) : _c('div', {}, [_c('b-nav-item', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    }
  }, [_c('b-button', {
    staticClass: "text-body mr-2",
    attrs: {
      "variant": "white",
      "to": {
        name: 'auth-register'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "d-block d-md-none",
    attrs: {
      "icon": "ClipboardIcon"
    }
  }), _c('span', {
    staticClass: "d-none d-md-block"
  }, [_vm._v("Daftar")])], 1), _c('b-button', {
    staticClass: "text-body",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'auth-login'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "d-block d-md-none",
    attrs: {
      "icon": "ClipboardIcon"
    }
  }), _c('span', {
    staticClass: "d-none d-md-block"
  }, [_vm._v("Masuk")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }