<template>
  <div class="" v-if="userData">
    <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name }}
          </p>
          <span class="user-status">{{ userData.level.nama_level }}</span>
        </div>
        <b-avatar size="40" :src="userData.avatar" variant="light-primary" badge class="badge-minimal" badge-variant="success">
          <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <b-dropdown-item v-if="userData.level.nama_level == 'Siswa'" :to="{ name: 'statistik' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Dashboard</span>
      </b-dropdown-item>

      <b-dropdown-item v-else-if="userData.level.nama_level == 'Super Admin'" :to="{ name: 'admin-dashboard' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="HomeIcon" class="mr-50" />
        <span>Dashboard</span>
      </b-dropdown-item>

      <b-dropdown-item v-else :to="{ name: 'user-admin-dashboard' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="HomeIcon" class="mr-50" />
        <span>Dashboard</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item></b-nav-item-dropdown
    >
  </div>
  <div class="" v-else>
    <b-nav-item class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
      <b-button variant="white" class="text-body mr-2" :to="{ name: 'auth-register' }">
        <feather-icon icon="ClipboardIcon" class="d-block d-md-none" />
        <span class="d-none d-md-block">Daftar</span>
      </b-button>
      <b-button variant="primary" class="text-body" :to="{ name: 'auth-login' }">
        <feather-icon icon="ClipboardIcon" class="d-block d-md-none" />
        <span class="d-none d-md-block">Masuk</span>
      </b-button>
    </b-nav-item>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
