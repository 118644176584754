var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed",
    staticStyle: {
      "background-color": "white",
      "box-shadow": "none"
    }
  }, [_c('b-container', {
    staticClass: "text-light text-center"
  }, [_c('b-col', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "white",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _vm._v("Konfirmasi "), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v("Pembayaran")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-img', {
    staticStyle: {
      "align": "center",
      "width": "150px"
    },
    attrs: {
      "src": _vm.img,
      "rounded": ""
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }