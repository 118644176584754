var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.profileData).length ? _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('nav', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('profile-header', {
    attrs: {
      "header-data": _vm.profileData.header
    }
  })], 1), _c('section', {
    attrs: {
      "id": "profile-info"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('konfirmasi-pembayaran')], 1)], 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }